import {
    AdminPanelSettingsOutlined,
    AutoStoriesOutlined,
    Ballot,
    CampaignOutlined,
    CardMembershipOutlined,
    CardMembershipOutlinedIcon,
    ContactMailOutlined,
    ContactPhoneOutlined,
    EqualizerOutlined,
    EventOutlined,
    FestivalOutlined,
    GavelOutlined,
    Groups3Outlined,
    InsertInvitationOutlined,
    KeyboardCommandKey,
    LinearScaleOutlined,
    MenuBookOutlined,
    NoAccountsOutlined,
    NotificationsNoneOutlined,
    PaymentsOutlined,
    PersonOutlineOutlined,
    PolicyOutlined,
    SettingsOutlined,
    SpeedOutlined,
    SubscriptionsOutlined,
    VerifiedOutlinedIcon,
    VideoCameraFrontOutlined
} from '@mui/icons-material';

import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import AddToQueueOutlinedIcon from '@mui/icons-material/AddToQueueOutlined';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AllOutIcon from '@mui/icons-material/AllOut';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import EmergencyRecordingIcon from '@mui/icons-material/EmergencyRecording';
import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

/* eslint-disable prettier/prettier */

// icons
const icons = {
    SpeedOutlined,
    SettingsOutlined,
    InsertInvitationOutlined,
    FestivalOutlined,
    Groups3Outlined,
    AutoStoriesOutlined,
    PersonOutlineOutlined,
    ContactMailOutlined,
    ContactPhoneOutlined,
    PolicyOutlined,
    GavelOutlined,
    EqualizerOutlined,
    MenuBookOutlined,
    RateReviewOutlinedIcon,
    VideoCameraFrontOutlined,
    AdminPanelSettingsOutlined,
    NoAccountsOutlined,
    CardMembershipOutlined,
    SubscriptionsOutlined,
    NotificationsNoneOutlined,
    CampaignOutlined,
    EventOutlined,
    LinearScaleOutlined,
    AddToQueueOutlinedIcon,
    PaymentsOutlined,
    ProductionQuantityLimitsIcon,
    Ballot,
    KeyboardCommandKey,
    CleanHandsIcon,
    CardMembershipOutlinedIcon,
    VerifiedOutlinedIcon,
    EmergencyRecordingIcon,
    NoiseControlOffIcon,
    AddToDriveIcon,
    AlignVerticalCenterIcon,
    AllOutIcon,
    SupervisorAccountIcon,
    StarHalfIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.SpeedOutlined,
            breadcrumbs: false
        },
        {
            id: 'user_management',
            title: 'User Management',
            type: 'collapse',
            // url: '/static',
            icon: icons.PersonOutlineOutlined,
            breadcrumbs: false,
            disabled: false,
            children: [
                {
                    id: 'users',
                    title: 'Users',
                    type: 'item',
                    url: '/user',
                    icon: icons.PersonOutlineOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'subscribed_user',
                    title: 'Subscribed User',
                    type: 'item',
                    url: '/subscribed_user',
                    disabled: false,
                    icon: icons.AdminPanelSettingsOutlined,
                    breadcrumbs: false
                },
                // {
                //     id: 'live_class_user',
                //     title: 'Live Class User',
                //     type: 'item',
                //     url: '/live_class/subscribed_user',
                //     disabled: false,
                //     icon: icons.VideoCameraFrontOutlined,
                //     breadcrumbs: false
                // },
                {
                    id: 'deleted_user',
                    title: 'Deleted Users',
                    type: 'item',
                    url: '/deleted_users',
                    icon: icons.NoAccountsOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'certificate',
                    title: 'Certificate',
                    type: 'item',
                    url: '/certificate',
                    icon: icons.CardMembershipOutlined,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'level_management',
            title: 'Course Level',
            type: 'item',
            url: '/level',
            icon: icons.MenuBookOutlined,
            disabled: false,
            breadcrumbs: false
        },
        {
            id: 'course_management',
            title: 'Course Management',
            type: 'item',
            url: '/course_management',
            icon: icons.MenuBookOutlined,
            disabled: false,
            breadcrumbs: false
        },
        {
            id: 'subscription_management',
            title: 'Subscription Management',
            type: 'collapse',
            // url: '/static',
            icon: icons.CardMembershipOutlined,
            breadcrumbs: false,
            disabled: false,
            children: [
                {
                    id: 'subscription',
                    title: 'Subscription',
                    type: 'item',
                    url: '/subscription',
                    icon: icons.CardMembershipOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'live_subscription',
                    title: 'Live Class Subscription',
                    type: 'item',
                    url: '/live_class_subscription',
                    icon: icons.SubscriptionsOutlined,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'notifications',
            title: 'Notification Management',
            type: 'item',
            url: '/notifications',
            icon: icons.NotificationsNoneOutlined,
            disabled: false,
            breadcrumbs: false
        },
        {
            id: 'promotional_posters',
            title: 'Promotional Posters',
            type: 'item',
            url: '/promotional_posters',
            icon: icons.CampaignOutlined,
            disabled: false,
            breadcrumbs: false
        },
        {
            id: 'event_gallery',
            title: 'Event Gallery',
            type: 'item',
            url: '/event_gallery',
            icon: icons.EventOutlined,
            disabled: false,
            breadcrumbs: false
        },
        {
            id: 'rating_review',
            title: 'Rating & review',
            type: 'item',
            url: '/rating_review',
            icon: icons.RateReviewOutlinedIcon,
            disabled: false,
            breadcrumbs: false
        },
        {
            id: 'static_pages',
            title: 'Static Management',
            type: 'collapse',
            // url: '/static',
            icon: icons.KeyboardCommandKey,
            breadcrumbs: false,
            disabled: false,
            children: [
                {
                    id: 'about_us',
                    title: 'About Us',
                    type: 'item',
                    url: '/static/about_us',
                    icon: icons.ContactPhoneOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'terms_condition',
                    title: 'Terms And Conditions',
                    type: 'item',
                    url: '/static/terms_condition',
                    icon: icons.GavelOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'privacy_policy',
                    title: 'Privacy Policy',
                    type: 'item',
                    url: '/static/privacy_policy',
                    icon: icons.PolicyOutlined,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'e_commerce',
            title: 'E-Commerce Management',
            type: 'collapse',
            // url: '/static',
            icon: icons.EqualizerOutlined,
            breadcrumbs: false,
            disabled: false,
            children: [
                {
                    id: 'manage_product',
                    title: 'Manage Product',
                    type: 'item',
                    url: '/product/manage_product',
                    icon: icons.ContactPhoneOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'order_history',
                    title: 'Order History',
                    type: 'item',
                    url: '/order_history',
                    icon: icons.GavelOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'size_chart',
                    title: 'Size Chart',
                    type: 'item',
                    url: '/size-chart',
                    icon: icons.Ballot,
                    breadcrumbs: false
                },
                {
                    id: 'product_deliver',
                    title: 'Product Deliver',
                    type: 'item',
                    url: '/product-deliver',
                    icon: icons.ProductionQuantityLimitsIcon,
                    breadcrumbs: false
                },
                {
                    id: 'delivery_charge',
                    title: 'Delivery Charge',
                    type: 'item',
                    url: '/delivery-charge',
                    icon: icons.GavelOutlined,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'additional_pages',
            title: 'General Settings',
            type: 'collapse',
            // url: '/static',
            icon: icons.SettingsOutlined,
            breadcrumbs: false,
            disabled: false,
            children: [
                {
                    id: 'success_stories',
                    title: 'Success Stories',
                    type: 'item',
                    url: '/success_stories',
                    icon: icons.AutoStoriesOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'meet_team',
                    title: 'Meet Our Team',
                    type: 'item',
                    url: '/meet_team',
                    icon: icons.Groups3Outlined,
                    breadcrumbs: false
                },
                {
                    id: 'payment_mode',
                    title: 'Payment Mode',
                    type: 'item',
                    url: '/payment_mode',
                    icon: icons.PaymentsOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'contact_us',
                    title: 'Contact Us',
                    type: 'item',
                    url: '/contact_us',
                    icon: icons.ContactPhoneOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'walkthrough',
                    title: 'Walkthrough Screen',
                    type: 'item',
                    url: '/walkthrough',
                    icon: icons.LinearScaleOutlined,
                    breadcrumbs: false
                }
                // {
                //     id: 'reason',
                //     title: 'So Many Reason',
                //     type: 'item',
                //     url: '/reason',
                //     icon: icons.Ballot,
                //     breadcrumbs: false
                // }
            ]
        },
        //   Premium Feachure >>>>>>>>>>>>>>>>>>>>>>
        {
            id: 'premium_feachure',
            title: 'Premium Management',
            type: 'collapse',
            // url: '/static',
            icon: icons.NoiseControlOffIcon,
            breadcrumbs: false,
            disabled: false,
            children: [
                {
                    id: 'popular_courses',
                    title: 'Courese Management',
                    type: 'item',
                    url: '/popular/courses',
                    icon: icons.AutoStoriesOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'recorded_courses',
                    title: 'Recorded Courese',
                    type: 'item',
                    url: '/recorded-courses',
                    icon: icons.EmergencyRecordingIcon,
                    breadcrumbs: false
                },
                {
                    id: 'live_courses',
                    title: 'Live Courese',
                    type: 'item',
                    url: '/live-courses',
                    icon: icons.AutoStoriesOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'e-books',
                    title: 'Yoga E-Books',
                    type: 'item',
                    url: '/yoga-e-books',
                    icon: icons.AutoStoriesOutlined,
                    breadcrumbs: false
                },
                {
                    id: 'premium-terms-condition',
                    title: 'Terms And Conditions',
                    type: 'item',
                    url: '/static/premium-terms-condition',
                    icon: icons.GavelOutlined,
                    breadcrumbs: false
                },
                // {
                //     id: 'static-content',
                //     title: 'Static Content',
                //     type: 'item',
                //     url: '/static-content',
                //     icon: icons.AddToDriveIcon,
                //     breadcrumbs: false
                // },
                {
                    id: 'course-comprise',
                    title: 'Course Comprise',
                    type: 'item',
                    url: '/course-comprise',
                    icon: icons.AlignVerticalCenterIcon,
                    breadcrumbs: false
                },
                {
                    id: 'eligibility',
                    title: 'Eligibility Management',
                    type: 'item',
                    url: '/eligibility',
                    icon: icons.AllOutIcon,
                    breadcrumbs: false
                },
                {
                    id: 'teachers',
                    title: 'Teacher Management',
                    type: 'item',
                    url: '/teachers',
                    icon: icons.SupervisorAccountIcon,
                    breadcrumbs: false
                },
                {
                    id: 'rating',
                    title: 'Teacher Rating',
                    type: 'item',
                    url: '/rating',
                    icon: icons.StarHalfIcon,
                    breadcrumbs: false
                },
                {
                    id: 'splash-screen',
                    title: 'Splash Screen',
                    type: 'item',
                    url: '/splash-screen',
                    icon: icons.StarHalfIcon,
                    breadcrumbs: false
                },
                {
                    id: 'course-histories',
                    title: 'Purchase Course History',
                    type: 'item',
                    url: '/course-histories',
                    icon: icons.SupervisorAccountIcon,
                    breadcrumbs: false
                },
                {
                    id: 'booking-histories',
                    title: 'Teacher Booking History',
                    type: 'item',
                    url: '/booking-histories',
                    icon: icons.SupervisorAccountIcon,
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'customer_experince',
            title: 'Customer Experices Management',
            type: 'item',
            url: '/customer-experince',
            icon: icons.CleanHandsIcon,
            disabled: false,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
