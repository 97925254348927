import { ReasonReducer } from './reason.slice';
import { SizeChartReducer } from './sizeChart.slice';
import { authReducer } from './auth.slice';
import { combineReducers } from 'redux';
import { contactUsReducer } from './contact.slice';
import { courseCompriseReducer } from './courseComprise.slice';
import { courseContentReducer } from './courseContent.slice';
import { courseHistoryReducer } from './courseHistory.slice';
import { courseLevelReducer } from './level.slice';
import { courseReducer } from './course.slice';
import { courseUploadVideoReducer } from './courseUploadVideo.slice';
import { customerExperincesReducer } from './customer_experince.slice';
import { deliveryChargeReducer } from './delivey_charge.slice';
import { ecommerceReducer } from './ecommerce.slice';
import { eligibilityReducer } from './eligibility.slice';
import { eventGalleryReducer } from './eventGallery.slice';
import { generalSettingsReducer } from './generalSettings.slice';
import { liveCoursesReducer } from './liveCourse.slice';
import { notificationReducer } from './notifications.slice';
import { paymentModeReducer } from './paymentMode.slice';
import { popularCourseReducer } from './popularCourses.slice';
import { postersReducer } from './posters.slice';
import { productDeliverReducer } from './productDeliver.slice';
import { ratingAndReviewReducer } from './ratingAndReview.slice';
import { recordedCoursesReducer } from './recordedCourse.slice';
import { spalashScreenReducer } from './splash_screen.slice';
import { staticContentReducer } from './staticContent.slice';
import { staticReducer } from './static.slice';
import { subscriptionReducer } from './subscription.slice';
import { teacherScheduleReducer } from './teacherSchedule.slice';
import { teachersReducer } from './teachers.slice';
import { uploadVideoReducer } from './uploadVideo.slice';
import { usersReducer } from './users.slice';
import { eBooksReducer } from './eBooks.slice';

// third-party

// ==============================|| COMBINE REDUCERS ||============================== //

const combinedReducer = combineReducers({
    staticReducer: staticReducer,
    usersReducer,
    ReasonReducer,
    authReducer: authReducer,
    generalSettingsReducer,
    eventGalleryReducer,
    postersReducer,
    eBooksReducer,
    courseReducer,
    ratingAndReviewReducer,
    subscriptionReducer,
    notificationReducer,
    ecommerceReducer,
    contactUsReducer: contactUsReducer,
    courseLevelReducer: courseLevelReducer,
    paymentModeReducer: paymentModeReducer,
    SizeChartReducer: SizeChartReducer,
    productDeliverReducer: productDeliverReducer,
    customerExperincesReducer: customerExperincesReducer,
    deliveryChargeReducer: deliveryChargeReducer,
    popularCourseReducer: popularCourseReducer,
    liveCoursesReducer: liveCoursesReducer,
    recordedCoursesReducer: recordedCoursesReducer,
    staticContentReducer: staticContentReducer,
    courseCompriseReducer: courseCompriseReducer,
    eligibilityReducer: eligibilityReducer,
    teachersReducer: teachersReducer,
    courseContentReducer: courseContentReducer,
    uploadVideoReducer: uploadVideoReducer,
    teacherScheduleReducer: teacherScheduleReducer,
    courseUploadVideoReducer: courseUploadVideoReducer,
    courseHistoryReducer: courseHistoryReducer,
    spalashScreenReducer: spalashScreenReducer
});

const rootReducers = (state, action) => {
    if (action?.type === 'USER_LOGOUT') {
        return state;
    }
    return combinedReducer(state, action);
};

export default rootReducers;
