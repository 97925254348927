import {
    AddPhotoAlternate,
    CheckBoxOutlineBlankOutlined,
    CheckBoxOutlined,
    DeleteOutline,
    Done,
    EditOutlined,
    InfoOutlined,
    RestoreFromTrashOutlined,
    ToggleOffOutlined,
    ToggleOnOutlined,
    ViewListOutlined,
    VisibilityOffOutlined,
    VisibilityOutlined
} from '@mui/icons-material';
import { Button, Checkbox, IconButton, Tooltip } from '@mui/material';

import { GridActionsCellItem } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import LoupeIcon from '@mui/icons-material/Loupe';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import React from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import QuizIcon from '@mui/icons-material/Quiz';
const getIcon = (type) => {
    switch (type) {
        case 'Edit':
            return <EditOutlined />;
        case 'Save':
            return <Done />;
        case 'Show':
            return <InfoOutlined />;
        case 'Add Course Content':
            return <LoupeIcon />;
        case 'Add What You Will Learn':
                return <QuizIcon />;
        case 'View':
            return <RemoveRedEyeIcon />;
        case 'Delete':
            return <DeleteOutline />;
        case 'Chapter List':
            return <ViewListOutlined />;
        case 'Video Upload':
            return <VideoChatIcon />;
        case 'Active':
            return <ToggleOnOutlined />;
        case 'Inactive':
            return <ToggleOffOutlined />;
        case 'Yes':
            return <ToggleOnOutlined />;
        case 'No':
            return <ToggleOffOutlined />;
        case 'Is Popular':
            return <ToggleOffOutlined />;
        case 'Not Popular':
            return <ToggleOnOutlined />;
        case 'Recover':
            return <RestoreFromTrashOutlined />;
        case 'Upload Image':
            return <AddPhotoAlternate />;
        case 'Schedule Time':
            return <ScheduleIcon />;
        case 'Upload Pdf':
            return <PictureAsPdfIcon />;
        case 'Checkbox':
            return <CheckBoxOutlineBlankOutlined />;
        default:
            return <EditOutlined />;
    }
};

const CustomActionButton = ({ to, type, onClick }) => {
    return (
        <GridActionsCellItem
            sx={{
                '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent'
                },
                minWidth: 25
            }}
            component={to ? Link : Button}
            to={to}
            icon={
                type === 'Checkbox' ? (
                    <Checkbox icon={<CheckBoxOutlineBlankOutlined />} checkedIcon={<CheckBoxOutlined />} color="primary" />
                ) : (
                    <Tooltip title={type === 'Active' ? 'Inactive' : type === 'Inactive' ? 'Active' : type}>
                        <IconButton
                            sx={{
                                opacity: type === 'Inactive' ? '0.5' : type === 'Not Popular' ? '0.5' : type === 'No' ? '0.5' : '1.0',
                                '&.MuiButtonBase-root:hover': {
                                    bgcolor: 'transparent'
                                }
                            }}
                            color="primary"
                            size="large"
                        >
                            {getIcon(type)}
                        </IconButton>
                    </Tooltip>
                )
            }
            onClick={onClick}
            label={type}
        />
    );
};

export { CustomActionButton };
