/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'recordedCourse';

const initialState = entityAdapter.getInitialState({
    allRecordedCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    recordedCoursesByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addRecordedCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    editRecordedCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteRecordedCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusRecordedCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    allStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
    staticContentByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    staticContentByIdError: null,
    staticContentById:null,
    allStaticContent: [],
    allRecordedCoursess: [],
    recordedCoursesById: null,
    allRecordedCoursesError: null,
    allStaticContentError: null,
    addRecordedCoursesError: null,
    editRecordedCoursesError: null,
    deleteRecordedCoursesError: null,
    changeStatusRecordedCoursesError: null,
    recordedCoursesByIdError: null,
    addStaticContentError: null,
    isPopularCourseError: null,
    isPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED
});

/**
 * All RecordedCourses Action
 */

export const allRecordedCoursesAction = createAsyncThunk(`${SLICE_NAME}/allRecordedCoursesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-record-courses',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add RecordedCourses Action
 */

export const addRecordedCoursesAction = createAsyncThunk(`${SLICE_NAME}/addRecordedCoursesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/record-course-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  RecordedCourses by ID Action
 */

export const recordedCoursesByIdAction = createAsyncThunk(`${SLICE_NAME}/recordedCoursesByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-record-course/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit RecordedCourses Action
 */

export const editRecordedCoursesAction = createAsyncThunk(`${SLICE_NAME}/editRecordedCoursesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/record-course-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete RecordedCourses Action
 */

export const deleteRecordedCoursesAction = createAsyncThunk(`${SLICE_NAME}/deleteRecordedCoursesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/record-course-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status RecordedCourses Action
 */

export const changeStatusRecordedCoursesAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusRecordedCoursesAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/record-course-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);
/**
 * Change Status RecordedCourses Action
 */

export const addIsPopularCourseAction = createAsyncThunk(`${SLICE_NAME}/addIsPopularCourseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/is_polpular_course',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Record Multiple Pdf by ID Action
 */

export const getRecordPdfByIdAction = createAsyncThunk(`${SLICE_NAME}/getRecordPdfByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: `/get-upload-pdf?record_id=${params.record_id}`,
            method: 'GET',
            params: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Add Multiple Record PDF by ID Action
 */

export const addMultiplePdfAction = createAsyncThunk(`${SLICE_NAME}/addMultiplePdfAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: 'upload-pdf/create',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Delete Record Multiple Pdf by ID Action
 */

export const deleteMultiplePdfAction = createAsyncThunk(`${SLICE_NAME}/deleteMultiplePdfAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/upload-pdf/delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});


/**
 * All Recorded Static Content (What you will learn) Action
 */

export const allStaticContentAction = createAsyncThunk(`${SLICE_NAME}/allStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/record-static-content/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Add StaticContent Action
 */

 export const addStaticContentAction = createAsyncThunk(`${SLICE_NAME}/addStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/record-static-content-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  StaticContent by ID Action
 */

 export const staticContentByIdAction = createAsyncThunk(`${SLICE_NAME}/staticContentByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/record-static-content-view/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

export const editStaticContentAction = createAsyncThunk(`${SLICE_NAME}/editStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/record-static-content-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Delete StaticContent Action
 */

 export const deleteStaticContentAction = createAsyncThunk(`${SLICE_NAME}/deleteStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/record-static-content-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Slice for all reducres
 */
const recordedCoursesSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetRecordedCoursesState: (state, action) => {
            return {
                ...state,
                recordedCoursesByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addRecordedCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
                editRecordedCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteRecordedCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusRecordedCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
                addStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
                recordedCoursesById: null,
                recordedCoursesByIdError: null,
                addRecordedCoursesError: null,
                editRecordedCoursesError: null,
                deleteRecordedCoursesError: null,
                changeStatusRecordedCoursesError: null,
                isPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                isPopularCourseError: null,
                allRecordPdfListLoadingStatus: LoadingStatus.NOT_LOADED,
                allRecordPdfListError: null,
                addMultiplePdfLoadingStatus: LoadingStatus.NOT_LOADED,
                addMultiplePdfError: null,
                deleteMultiplePdfLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteMultiplePdfError: null,
                staticContentByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                staticContentByIdError: null,
                staticContentById:null,
                editStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
                editStaticContentError: null,
                deleteStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteStaticContentError:null,


            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All RecordedCourses action
            .addCase(allRecordedCoursesAction.pending, (state, action) => {
                state.allRecordedCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allRecordedCoursesAction.fulfilled, (state, action) => {
                state.allRecordedCoursesLoadingStatus = LoadingStatus.LOADED;
                state.allRecordedCoursess = action.payload?.data ?? [];
            })
            .addCase(allRecordedCoursesAction.rejected, (state, action) => {
                state.allRecordedCoursesLoadingStatus = LoadingStatus.FAILED;
                state.allRecordedCoursesError = action.payload || action.error.message;
            })
            .addCase(recordedCoursesByIdAction.pending, (state, action) => {
                state.recordedCoursesByIdError = null;
                state.recordedCoursesByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(recordedCoursesByIdAction.fulfilled, (state, action) => {
                state.recordedCoursesByIdLoadingStatus = LoadingStatus.LOADED;
                state.recordedCoursesById = action.payload?.data;
            })
            .addCase(recordedCoursesByIdAction.rejected, (state, action) => {
                state.recordedCoursesByIdLoadingStatus = LoadingStatus.FAILED;
                state.recordedCoursesByIdError = action.payload || action.error.message;
            })

            // Add RecordedCourses action
            .addCase(addRecordedCoursesAction.pending, (state, action) => {
                state.addRecordedCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addRecordedCoursesAction.fulfilled, (state, action) => {
                state.addRecordedCoursesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('RecordedCourses added successfully.');
            })
            .addCase(addRecordedCoursesAction.rejected, (state, action) => {
                state.addRecordedCoursesLoadingStatus = LoadingStatus.FAILED;
                state.addRecordedCoursesError = action.payload || action.error.message;
            })
            // Edit RecordedCourses action
            .addCase(editRecordedCoursesAction.pending, (state, action) => {
                state.editRecordedCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editRecordedCoursesAction.fulfilled, (state, action) => {
                state.editRecordedCoursesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('RecordedCourses updated successfully.');
            })
            .addCase(editRecordedCoursesAction.rejected, (state, action) => {
                state.editRecordedCoursesLoadingStatus = LoadingStatus.FAILED;
                state.editRecordedCoursesError = action.payload || action.error.message;
            })
            // Delete RecordedCourses action
            .addCase(deleteRecordedCoursesAction.pending, (state, action) => {
                state.deleteRecordedCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteRecordedCoursesAction.fulfilled, (state, action) => {
                state.deleteRecordedCoursesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('RecordedCourses deleted successfully.');
            })
            .addCase(deleteRecordedCoursesAction.rejected, (state, action) => {
                state.deleteRecordedCoursesLoadingStatus = LoadingStatus.FAILED;
                state.deleteRecordedCoursesError = action.payload || action.error.message;
            })
            // Change Status RecordedCourses action
            .addCase(changeStatusRecordedCoursesAction.pending, (state, action) => {
                state.changeStatusRecordedCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusRecordedCoursesAction.fulfilled, (state, action) => {
                state.changeStatusRecordedCoursesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('RecordedCourses status updated successfully.');
            })
            .addCase(changeStatusRecordedCoursesAction.rejected, (state, action) => {
                state.changeStatusRecordedCoursesLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusRecordedCoursesError = action.payload || action.error.message;
            })
            // Add IsPopularCourses action
            .addCase(addIsPopularCourseAction.pending, (state, action) => {
                state.isPopularCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addIsPopularCourseAction.fulfilled, (state, action) => {
                state.isPopularCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Added is Popular Course successfully.');
            })
            .addCase(addIsPopularCourseAction.rejected, (state, action) => {
                state.isPopularCourseLoadingStatus = LoadingStatus.FAILED;
                state.isPopularCourseError = action.payload || action.error.message;
            })

            //all Record Multiple Pdf action
            .addCase(getRecordPdfByIdAction.pending, (state, action) => {
                state.allRecordPdfListLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getRecordPdfByIdAction.fulfilled, (state, action) => {
                state.allRecordPdfListLoadingStatus = LoadingStatus.LOADED;
                state.allRecordPdfList = action.payload?.data;
            })
            .addCase(getRecordPdfByIdAction.rejected, (state, action) => {
                state.allRecordPdfListLoadingStatus = LoadingStatus.FAILED;
                state.allRecordPdfListError = action.payload || action.error.message;
                state.allRecordPdfList = [];
            })

            // Add Multiple Record Pdf Upload action
            .addCase(addMultiplePdfAction.pending, (state, action) => {
                state.addMultiplePdfLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addMultiplePdfAction.fulfilled, (state, action) => {
                state.addMultiplePdfLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Record Pdf added successfully.');
            })
            .addCase(addMultiplePdfAction.rejected, (state, action) => {
                state.addMultiplePdfLoadingStatus = LoadingStatus.FAILED;
                state.addMultiplePdfError = action.payload || action.error.message;
            })
            // delete Record Multiple Pdf action
            .addCase(deleteMultiplePdfAction.pending, (state, action) => {
                state.deleteMultiplePdfLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteMultiplePdfAction.fulfilled, (state, action) => {
                state.deleteMultiplePdfLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Record Pdf deleted successfully.');
            })
            .addCase(deleteMultiplePdfAction.rejected, (state, action) => {
                state.deleteMultiplePdfLoadingStatus = LoadingStatus.FAILED;
                state.deleteMultiplePdfError = action.payload || action.error.message;
            })
             // All Recorded Static Content action
             .addCase(allStaticContentAction.pending, (state, action) => {
                state.allStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allStaticContentAction.fulfilled, (state, action) => {
                state.allStaticContentLoadingStatus = LoadingStatus.LOADED;
                state.allStaticContent = action.payload?.data ?? [];
            })
            .addCase(allStaticContentAction.rejected, (state, action) => {
                state.allStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.allStaticContentError = action.payload || action.error.message;
            })
            // Add StaticContent action
            .addCase(addStaticContentAction.pending, (state, action) => {
                state.addStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addStaticContentAction.fulfilled, (state, action) => {
                state.addStaticContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content added successfully.');
            })
            .addCase(addStaticContentAction.rejected, (state, action) => {
                state.addStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.addStaticContentError = action.payload || action.error.message;
            })
            .addCase(staticContentByIdAction.pending, (state, action) => {
                state.staticContentByIdError = null;
                state.staticContentByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(staticContentByIdAction.fulfilled, (state, action) => {
                state.staticContentByIdLoadingStatus = LoadingStatus.LOADED;
                state.staticContentById = action.payload?.data;
            })
            .addCase(staticContentByIdAction.rejected, (state, action) => {
                state.staticContentByIdLoadingStatus = LoadingStatus.FAILED;
                state.staticContentByIdError = action.payload || action.error.message;
            })
             // Edit StaticContent action
             .addCase(editStaticContentAction.pending, (state, action) => {
                state.editStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editStaticContentAction.fulfilled, (state, action) => {
                state.editStaticContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content updated successfully.');
            })
            .addCase(editStaticContentAction.rejected, (state, action) => {
                state.editStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.editStaticContentError = action.payload || action.error.message;
            })
             // Delete StaticContent action
             .addCase(deleteStaticContentAction.pending, (state, action) => {
                state.deleteStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteStaticContentAction.fulfilled, (state, action) => {
                state.deleteStaticContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content deleted successfully.');
            })
            .addCase(deleteStaticContentAction.rejected, (state, action) => {
                state.deleteStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.deleteStaticContentError = action.payload || action.error.message;
            })

    }
});

/*
 * Export reducer for store configuration.
 */
export const recordedCoursesReducer = recordedCoursesSlice.reducer;

export const recordedCoursesActions = recordedCoursesSlice.actions;
